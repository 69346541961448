<template>
  <div>
    <div class="iss-main-grid">
      <a-spin :spinning="spinning" size="large">
        <div class="iss-search-wrap">
          <search-form :items="items" @fnSearch="handleFnSearch" />
        </div>
        <div class="iss-grid-wrap mt-16">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            :url="url"
            allow-selection
            :search="search"
            :btn-operation="btnOperation"
            :scroll="{ x: 900, y: gridHeight }"
          >
            <template #autoSwitch="{ record }">
              <a-switch
                v-if="record.tagTypeCode !== 'custom'"
                :checked="record.autoSwitch"
                checked-children="启用"
                un-checked-children="禁用"
                @click="handleClickSwitch(record)"
              />
            </template>
            <template #number="{ record }">
              <div class="iss-user">
                <a-tooltip title="查看成员" placement="top">
                  <a-button
                    type="primary"
                    size="middle"
                    shape="circle"
                    ghost
                    @click="handleClickViewMember(record)"
                  >
                    <template #icon><UserOutlined /></template>
                  </a-button>
                </a-tooltip>
                <span style="padding-left: 5px">{{ record.number }}</span>
              </div>
            </template>
            <template #executState="{ text }">
              <a-tag color="success" v-if="text === 'ExecuteSuccessfully'"
                >执行成功</a-tag
              >
              <a-tag color="processing" v-else-if="text === 'Running'">
                执行中
              </a-tag>
              <a-tag color="error" v-else-if="text === 'ExecFailure'">
                执行失败
              </a-tag>
              <a-tag color="warning" v-else> 待执行 </a-tag>
            </template>
            <template #operation="{ record }">
              <operation
                :options="
                  record.executState === 'Running'
                    ? options.filter(item => item.type !== 'update')
                    : options
                "
                :record="record"
              />
            </template>
          </grid>
        </div>
      </a-spin>
    </div>
    <a-modal
      :visible="visible"
      title="分群成员列表"
      :width="800"
      centered
      :footer="null"
      :maskClosable="false"
      @cancel="visible = false"
      :afterClose="handleAfterClose"
    >
      <user :group-id="groupId" />
    </a-modal>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, reactive, toRefs } from 'vue';
import { Tooltip, Spin, message, Tag, Switch } from 'ant-design-vue';
import { UserOutlined } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import User from './components/user';
import Clustering from '@/api/clustering.js';

export default {
  name: 'group',
  components: {
    ATooltip: Tooltip,
    ASpin: Spin,
    ATag: Tag,
    SearchForm,
    Grid,
    Operation,
    User,
    UserOutlined,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      visible: false,
      search: {},
      spinning: false,
      groupId: '',
    });

    const handleFnSearch = value => {
      state.search = value;
    };
    const handleAfterClose = () => {
      state.groupId = '';
    };
    const handleClickViewMember = data => {
      state.visible = true;
      state.groupId = data.id;
    };
    const edit = data => {
      if (data.id) {
        router.push({
          name: 'groupEdit',
          params: { id: data.id, status: 'edit' },
        });
      } else {
        router.push({
          name: 'groupEdit',
          params: { id: 'add', status: 'add' },
        });
      }
    };
    const deletes = data => {
      Clustering.delete('group:delete', { ids: [data.id] }).then(() => {
        message.success('操作成功');
        gridRef.value.refreshGrid();
      });
    };
    const exec = data => {
      state.spinning = true;
      Clustering.runGroupRule('group:exec', {
        groupIds: [data.id],
      })
        .then(() => {
          message.success('操作成功！');
        })
        .finally(() => {
          state.spinning = false;
          gridRef.value.refreshGrid();
        });
    };
    // 分群取消执行
    // const CancelExec = data => {
    //   Clustering.CancelRunGroupRule('group:CancelExec', {
    //     groupIds: [data.id],
    //   })
    //     .then(() => {
    //       message.success('操作成功！');
    //     })
    //     .finally(() => {
    //       gridRef.value.refreshGrid();
    //     });
    // };

    const selectExec = data => {
      if (data.length > 0) {
        state.spinning = true;
        Clustering.runGroupRule('group:exec', {
          groupIds: data,
        })
          .then(() => {
            message.success('操作成功！');
          })
          .finally(() => {
            state.spinning = false;
            gridRef.value.refreshGrid();
          });
      } else {
        message.info('请先选择数据');
      }
    };
    const goWatch = data => {
      router.push({
        name: 'groupEdit',
        params: { id: data.id, status: 'watch' },
      });
    };
    return {
      exec,
      // CancelExec,
      deletes,
      edit,
      handleClickViewMember,
      handleAfterClose,
      handleFnSearch,
      gridRef,
      ...toRefs(state),
      items: [{ key: 'name', label: '群组名称' }],
      columns: [
        { dataIndex: 'name', title: '群组名称', width: 200, ellipsis: true },
        {
          key: 'number',
          title: '客户数量',
          slots: { customRender: 'number' },
          width: 120,
          ellipsis: true,
        },
        {
          key: 'autoSwitch',
          title: '自动执行',
          slots: { customRender: 'autoSwitch' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'executState',
          title: '执行状态',
          slots: { customRender: 'executState' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'createUserName',
          title: '创建人',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'group:add',
          fnClick: edit,
        },
        {
          type: 'selectExec',
          label: '批量执行',
          icon: 'SwitcherOutlined',
          permission: 'group:exec',
          fnClick: selectExec,
        },
      ],
      options: [
        {
          type: 'exec',
          icon: 'PlayCircleOutlined',
          permission: 'group:exec',
          label: '执行',
          fnClick: exec,
          show: record => {
            if (record.executState === 'Running') {
              return false;
            } else {
              return true;
            }
          },
        },
        // {
        //   type: 'CancelExec',
        //   icon: 'CloseCircleOutlined',
        //   permission: 'group:CancelExec',
        //   label: '取消执行',
        //   fnClick: CancelExec,
        //   show: record => {
        //     if (record.executState === 'Running') {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //
        //   },
        // },
        {
          type: 'watch',
          icon: 'EyeOutlined',
          permission: 'group:watch',
          label: '查看',
          fnClick: goWatch,
        },
        {
          type: 'update',
          permission: 'group:update',
          fnClick: edit,
          show: item => {
            if (item.executState === 'Running') {
              return false;
            } else {
              return true;
            }
          },
        },
        {
          type: 'delete',
          permission: 'group:delete',
          fnClick: deletes,
          show: item => {
            if (item.executState === 'Running') {
              return false;
            } else {
              return true;
            }
          },
        },
      ],
      url: Clustering.pageUrl,
      gridHeight: document.body.clientHeight - 359,
      handleClickSwitch({ id, autoSwitch }) {
        autoSwitch = !autoSwitch;
        Clustering.updateAutoSwitch('', { id, autoSwitch }).then(res => {
          if (res) {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          }
        });
      },
    };
  },
};
</script>

<style scoped>
.iss-user .ant-btn-icon-only.ant-btn-sm {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
}
</style>
